import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@/contexts/auth-context';

function RootPage() {
  const router = useRouter();
  const { isUserLoggedIn } = useContext(AuthContext);

  const newRoute = isUserLoggedIn ? '/merchants/overview' : '/login';
  router.push(newRoute);
  return <></>;
}

export default RootPage;
